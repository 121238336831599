import type { TSportEvent } from 'markets-store'
import { EVENT_ALLOWED_STATES } from 'markets-store/constants'
import { useMarketsStore } from './useMarketsStore'

/**
 * Получение списка всех спортивных событий определенного спорта
 * @example
 * const sportEvents = useSportEvents(sportId)
 */
export function useSportEventsBySportId(
  sportId: Ref<number> | number,
  type?: 'live' | 'prematch',
): ComputedRef<TSportEvent[]> {
  const MS = useMarketsStore()

  return computed(() => {
    // @ts-expect-error IndexedActual is pure magic
    const sportEventsByType = type ? MS.sportEvents[type] : MS.sportEvents

    return sportEventsByType.filter(
      (event: TSportEvent) =>
        EVENT_ALLOWED_STATES.includes(event.state) &&
        event.sportId === unref(sportId),
    )
  })
}
